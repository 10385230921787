import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Input,
  Comment,
  Avatar,
  Tag,
  Row,
  Col,
  Popconfirm,
  Typography,
  Radio,
  Image,
  Tooltip,
  Modal,
  Popover,
  message,
} from "antd";
import moment from "moment";
import {
  CloseOutlined,
  DeleteOutlined,
  UploadOutlined,
  PushpinOutlined,
  EyeOutlined,
  MobileTwoTone,
} from "@ant-design/icons";
import {
  createNote,
  updateNote,
  removeNote,
  fetchEntityNotes,
  fetchClientNotes,
} from "../../../../redux/action/note";
import Loading from "../../../../shared/loading/Loading.js";
import { formatTelForSms, toTitleCase } from "../../../../shared/utils/utils";
import ability from "../../../../user/Component/Auth/ability";
import "./Notes.scss";
import axios from "axios";

const { TextArea } = Input;
const { CheckableTag } = Tag;
const { Paragraph } = Typography;

const Notes = ({
  data,
  entityType,
  createNote,
  updateNote,
  removeNote,
  fetchEntityNotes,
  fetchClientNotes,
  note: { notes, clientNotes },
  auth: { user },
  isLoading,
}) => {
  useEffect(() => {
    if (user?.access?.includes("CLIENT")) {
      fetchEntityNotes(data?._id, "CLIENT");
      fetchClientNotes(data?.client_id, "CLIENT");
    } else {
      fetchEntityNotes(data?._id);
      fetchClientNotes(data?.client_id);
    }
  }, [data, updateNote]); // eslint-disable-line react-hooks/exhaustive-deps

  const [noteText, setNoteText] = useState("");
  const [smsText, setSmsText] = useState("");
  const [slackText, setSlackText] = useState("");
  const [selectedNote, setSelectedNote] = useState("");
  const [touched, setTouched] = useState(false);
  const [radioValue, setRadioValue] = useState("No");
  const [selectedTags, setSelectedTags] = useState(["ADMIN"]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [randomKey, setRandomKey] = useState();
  const [state, setState] = useState({
    existingPhotos: [],
    newPhotos: [],
  });
  const [s3, setS3] = useState();
  const [sendTextMsg, setSendTextMsg] = useState(false);
  const [sendToSlack, setSendToSlack] = useState(false);

  const { existingPhotos, newPhotos } = state;
  const tagsData = ["ADMIN", "CLIENT", "EDITOR", "PHOTOGRAPHER"];
  const handlePhotoEdit = (value) => {
    if (selectedNote !== "") {
      setIsModalVisible(false);
      const note = notes.filter((note) => note._id === selectedNote);
      if (state.newPhotos.length) {
        const handleUpload = async () => {
          let datas = new FormData();
          datas.append("path", `shoots/notes/high/${data.hsn}_`);
          for (var x = 0; x < state.newPhotos.length; x++) {
            const file = state.newPhotos[x].originFileObj;
            datas.append("file", file);
          }
          const upload = await axios.post(
            `${process.env.REACT_APP_ROOT_URL}/upload`,
            datas
          );
          const updatedPic = note[0].photos.gallery.concat(upload.data.urls);
          if (upload.status === 200) {
            setS3(upload.data.urls);
            setState({
              ...state,
              newPhotos: [],
            });
          }
          if (value !== "") {
            const reqBody = {
              text: note[0].text,
              photos: {
                gallery: updatedPic,
              },
            };
            updateNote(selectedNote, reqBody);
          }
          setNoteText("");
          setSelectedTags("ADMIN");
          setSelectedNote("");
        };
        handleUpload();
      }
    }
    let randomString = Math.random().toString(36);
    setRandomKey(randomString);
  };

  const handleChange = (noteId, value, image) => {
    const remove = notes.map(
      (note) =>
        note._id === noteId &&
        note.photos.gallery.filter((pic) => pic !== image)
    );
    const note = notes.filter((note) => note._id === noteId);
    const updatedPic = remove.filter((pic) => pic !== false);
    if (value !== "") {
      const reqBody = {
        text: value?.type === "click" ? note[0].text : value,
        photos: {
          gallery: updatedPic[0],
        },
      };
      updateNote(noteId, reqBody);
    }
    const deletePhotos = newPhotos.filter((target) => target.url !== noteId);
    setState({
      ...state,
      newPhotos: deletePhotos,
    });
  };

  useEffect(() => {
    const arr = [];
    if (newPhotos && newPhotos.length > 0) {
      newPhotos.forEach((url, index) => {
        if (url) {
          const altFileName = url.url.replace(/^(?:[^_]*_){2}/g, "");
          const regex = /\d+(?=\.\w+$)/g;
          const extension = url.url?.split(".").pop();
          let filename = url.url?.match(regex);
          filename = filename ? filename[0] + "." + extension : altFileName;
          const obj = {};
          const len = url.url?.split("/").length;
          obj.name = url.url?.split("/")[len - 1].split("_")[1];
          obj.url = url.url;
          obj.thumbUrl = url.url.replace("/high/", "/thumb/");
          obj.uid = index;
          obj._id = index + 1;
          obj.order = index + 1;
          obj.filename = filename;
          arr.push(obj);
        }
      });
      setState({ ...state, existingPhotos: arr, newPhotos: [] });
    }
  }, [s3]); // eslint-disable-line react-hooks/exhaustive-deps

  const showModal = (noteId) => {
    setSelectedNote(noteId);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setState({ newPhotos: [] });
    setIsModalVisible(false);
  };

  const renderNote = (note) => {
    return (
      <div key={note._id}>
        <Comment
          author={<span>{note?.user?.name} </span>}
          avatar={
            note?.user?.avatar ? (
              <Avatar alt={note?.user?.name} />
            ) : (
              <Avatar>{note?.user?.name?.charAt(0).toUpperCase()}</Avatar>
            )
          }
          content={
            <>
              {note?.user === user?._id ||
              note?.user?._id === user?._id ||
              ability.can("delete", "Notes") ? (
                <>
                  <Popconfirm
                    title="Are you sure？"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handleRemoveNote(note?._id)}
                  >
                    <Button
                      style={{ float: "right" }}
                      danger
                      type="link"
                      size="small"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                  {note.entity_type !== "Amenity" ? (
                    <Button
                      style={{ float: "right" }}
                      type="link"
                      size="small"
                      icon={<UploadOutlined />}
                      onClick={() => showModal(note._id)}
                    />
                  ) : null}
                  <Paragraph
                    ellipsis={{
                      rows: 3,
                      expandable: true,
                    }}
                    editable={{
                      onChange: (value) => handleChange(note._id, value),
                    }}
                  >
                    {note?.text}
                  </Paragraph>
                  <Modal
                    title="Edit Images"
                    footer={null}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                  >
                    <div className="contentBody">
                      <Image.PreviewGroup>
                        <Row gutter={[8, 8]} className="p-2">
                          {state.newPhotos.map((image) => (
                            <Col
                              xs={24}
                              sm={24}
                              md={6}
                              lg={6}
                              xl={6}
                              key={image.url}
                            >
                              <Image
                                src={image.thumbUrl}
                                width="100%"
                                alt="Note image"
                              />
                              <div>
                                <span>{image.filename}</span>
                                <span className="close-btn">
                                  <Button
                                    size="small"
                                    danger
                                    icon={<CloseOutlined />}
                                    onClick={() => handleChange(image.url)}
                                  />
                                </span>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Image.PreviewGroup>
                    </div>
                    <div style={{ padding: "30px" }}>
                      <Row className="pt-2" justify="space-between">
                        <Col span={12}>
                          <Tooltip
                            title="Upload one or more images"
                            placement="bottom"
                          >
                            <label
                              className="ant-upload"
                              style={{ color: "#1890ff" }}
                            >
                              <UploadOutlined style={{ marginRight: 10 }} />{" "}
                              Select
                              <Input
                                key={randomKey || ""}
                                multiple
                                type="file"
                                onChange={handlePhotoChange}
                                hidden
                              />
                            </label>
                          </Tooltip>
                        </Col>
                        <Col span={12}>
                          <Button
                            onClick={handlePhotoEdit}
                            type="primary"
                            className="float-right"
                          >
                            Upload
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Modal>
                  <Row gutter={[8, 8]} className="p-2">
                    <Image.PreviewGroup>
                      {note?.photos?.gallery?.map((image) => (
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} key={image}>
                          <Image src={image} width="100%" alt={image} />
                          <div>
                            <>
                              <span>{image.url}</span>
                              <span className="close-btn">
                                <Popconfirm
                                  title={`Are you sure you want to delete this image`}
                                  onConfirm={(value) =>
                                    handleChange(note._id, value, image)
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button
                                    danger
                                    size="small"
                                    icon={<CloseOutlined />}
                                  />
                                </Popconfirm>
                              </span>
                            </>
                          </div>
                        </Col>
                      ))}
                    </Image.PreviewGroup>
                  </Row>
                </>
              ) : (
                <>
                  <Paragraph
                    ellipsis={{
                      rows: 3,
                      expandable: true,
                    }}
                  >
                    {note?.text}
                  </Paragraph>
                  <Row gutter={[8, 8]} className="p-2">
                    <Image.PreviewGroup>
                      {note?.photos?.gallery?.map((image) => (
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} key={image}>
                          <Image src={image} width="100%" alt={image} />
                        </Col>
                      ))}
                    </Image.PreviewGroup>
                  </Row>
                </>
              )}
            </>
          }
          datetime={
            <span>
              {moment(note?._created_at).format("lll")}{" "}
              {note?.access?.includes("PHOTOGRAPHER") && (
                <Tag color="cyan">#photographer</Tag>
              )}
              {note?.access?.includes("EDITOR") && (
                <Tag color="volcano">#editor</Tag>
              )}
              {note?.access?.includes("CLIENT") &&
                !user?.access?.includes("CLIENT") && (
                  <Tag color="gold">#client</Tag>
                )}
              {note.sent_as_text && (
                <Tooltip title="Sent as text message">
                  <MobileTwoTone
                    twoToneColor="#52c41a"
                    style={{ marginLeft: 5 }}
                  />
                </Tooltip>
              )}
            </span>
          }
        />
      </div>
    );
  };

  const renderNotes = (selectedNotes) => {
    return (
      <div className="note-container">
        {isLoading && <Loading />}
        {!isLoading &&
          selectedNotes &&
          selectedNotes.map((note) => renderNote(note))}
      </div>
    );
  };

  let handleUpload;
  const handleNoteCreate = () => {
    if (noteText === "") {
      return;
    }
    setTouched(false);
    const tags = () =>
      user?.access.includes("CLIENT")
        ? [...selectedTags, "CLIENT"]
        : selectedTags;
    if (state.newPhotos.length) {
      handleUpload = async () => {
        let datas = new FormData();
        datas.append("path", `shoots/notes/high/${data.hsn}_`);
        for (var x = 0; x < state.newPhotos.length; x++) {
          const file = state.newPhotos[x].originFileObj;
          datas.append("file", file);
        }
        const upload = await axios.post(
          `${process.env.REACT_APP_ROOT_URL}/upload`,
          datas
        );
        if (upload.status === 200) {
          setS3(upload.data.urls);
          setState({
            ...state,
            newPhotos: [],
          });
        }
        createNote({
          text: noteText,
          photos: {
            gallery: upload.data.urls,
          },
          access: tags(),
          user: user._id,
          entity_id: data._id,
          entity_type: entityType,
        });
        setNoteText("");
        setSelectedTags("ADMIN");
      };
    } else {
      createNote({
        text: noteText,
        photos: [],
        access: tags(),
        user: user._id,
        entity_id: data._id,
        entity_type: entityType,
        sent_as_text: sendTextMsg ? true : false,
      });
      setNoteText("");
      setSelectedTags("ADMIN");
    }
    if (sendTextMsg) {
      handleSendSms();
    }
    if (sendToSlack) {
      handleSendSlack();
    }
    if (state.newPhotos.length > 0) {
      handleUpload();
    }
  };

  const handleSendSms = async () => {
    const smsPayload = {
      tel: formatTelForSms(data?.events?.photographer?.virtual_phone),
      message: smsText,
    };
    try {
      await fetch("https://hooks.zapier.com/hooks/catch/412492/be597lr/", {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(smsPayload),
      });
      message.success("Text message sent!");
      setSmsText("");
      setSlackText("");
      setSendTextMsg(false);
    } catch (error) {
      console.log(error);
      message.error("Text message failed to send!");
    }
  };

  const handleSendSlack = async () => {
    try {
      await fetch("https://hooks.zapier.com/hooks/catch/412492/26a49jo/", {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: slackText }),
      });
      message.success("Slack message sent!");
      setSlackText("");
      setSendToSlack(false);
      // Uncheck the checkbox
      // slackCheckboxRef.current.rcCheckbox.handleChange({
      //   target: { checked: false },
      // });
    } catch (error) {
      console.log(error);
      message.error("Slack message failed to send!");
    }
  };

  const onInputChange = (e) => {
    if (e.target.value !== "") {
      setTouched(true);
    } else {
      setTouched(false);
    }
    setNoteText(e.target.value);
    setSlackText(
      `📥 HS# ${data?.hsn} - ${data?.address?.street.replace("null", "")}:\n` +
        `${e.target.value}\n` +
        `🔗 ${process.env.REACT_APP_DOMAIN_URL}/admin/shoots/${data?._id}`
    );
    setSmsText(
      `⚠️ ${data?.address?.street.replace("null", "")}: ${e.target.value}`
    );
  };

  const onRadioChange = (e) => {
    let val = e.target.value;
    setRadioValue(val);
    if (val === "Yes") {
      setSelectedTags([...selectedTags, "CLIENT"]);
    } else {
      setSelectedTags(["ADMIN"]);
    }
  };

  // const handleSelectChange = (value) => {
  //   setFormData({ ...formData, access: value });
  // };

  const handleRemoveNote = (id) => {
    removeNote(id);
  };

  const handleTagChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);

    if (nextSelectedTags.length > 0) {
      setTouched(true);
    } else {
      setTouched(false);
    }
    setSelectedTags(nextSelectedTags);
  };

  const renderAllTags = () => {
    return (
      <>
        <span className="mr-2">
          <strong>Allow clients to see this note?</strong>
        </span>{" "}
        <Radio.Group onChange={onRadioChange} value={radioValue}>
          <Radio value="No">No</Radio>
          <Radio value="Yes">Yes</Radio>
        </Radio.Group>
        {/* <CheckableTag
          checked={selectedTags.includes("CLIENT")}
          onChange={(checked) => handleTagChange("CLIENT", checked)}
        >
          {selectedTags.includes("CLIENT") ? "No" : "Yes"}
        </CheckableTag> */}
      </>
    );
  };

  const renderClientTags = () => {
    return (
      <>
        <span className="mr-2">
          <strong>Who should see this note?</strong>
        </span>{" "}
        {tagsData
          .filter((tag) => tag !== "CLIENT")
          .map((filteredTag) => (
            <Checkbox
              key={filteredTag}
              checked={selectedTags.indexOf(filteredTag) > -1}
              onChange={(e) => handleTagChange(filteredTag, e.target.checked)}
            >
              {toTitleCase(filteredTag)}
            </Checkbox>
          ))}
      </>
    );
  };

  const renderTags = () => {
    return (
      <>
        <span className="mr-2">
          <strong>Who should see this note?</strong>
        </span>{" "}
        {tagsData
          .filter((tag) => tag !== "ADMIN")
          .map((filteredTag) => (
            <Checkbox
              key={filteredTag}
              checked={selectedTags.indexOf(filteredTag) > -1} // Check if tag is selected
              onChange={(e) => handleTagChange(filteredTag, e.target.checked)} // Handle change event
            >
              {toTitleCase(filteredTag)}
            </Checkbox>
          ))}
      </>
    );
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handlePhotoChange = async ({ target }) => {
    if (newPhotos && newPhotos.length > 0) {
      for (let index = 0; index < target.files.length; index++) {
        const base64Pic = await getBase64(target.files[index]);
        newPhotos.push({
          originFileObj: target.files[index],
          url: base64Pic,
          thumbUrl: base64Pic,
          _id: existingPhotos?.length + index + 1,
          order: existingPhotos?.length + index + 1,
        });
      }
      setState({
        ...state,
        newPhotos: newPhotos,
      });
    } else {
      const newPhotos = [];
      for (let index = 0; index < target.files.length; index++) {
        const base64Pic = await getBase64(target.files[index]);
        newPhotos.push({
          originFileObj: target.files[index],
          url: base64Pic,
          thumbUrl: base64Pic,
          _id: existingPhotos?.length + index + 1,
          order: existingPhotos?.length + index + 1,
        });
      }
      setState({
        ...state,
        newPhotos,
      });
    }
  };

  let amenityNote = [];
  let normalNotes = [];
  if (notes)
    notes.forEach((note) => {
      if (note.entity_type === "Amenity") {
        amenityNote.push(note);
      } else {
        normalNotes.push(note);
      }
    });
  return (
    <div className="notes">
      {amenityNote?.length ? (
        <div className="pinned-notes">
          <h5 className="p-2">
            <PushpinOutlined />
            Amenity Notes
          </h5>
          {renderNotes(amenityNote)}
        </div>
      ) : null}

      {clientNotes?.length > 0 && (
        <div className="pinned-notes" style={{ background: "#f0fbe4" }}>
          <h5 className="p-2">
            <PushpinOutlined /> Pinned Notes
          </h5>
          {renderNotes(clientNotes)}
        </div>
      )}

      <div>{notes?.length > 0 ? renderNotes(normalNotes) : null}</div>
      <div>
        <Row style={{ padding: 10, backgroundColor: "#F3F7FF" }}>
          {entityType !== "Equipment" && (
            <Col span={24} className="mb-2">
              {user?.access?.includes("CLIENT")
                ? renderClientTags()
                : renderTags()}
            </Col>
          )}
          <Col span={24}>
            <TextArea
              name="text"
              value={noteText}
              rows={2}
              onChange={(e) => onInputChange(e)}
              showCount={sendTextMsg}
              maxLength={sendTextMsg ? 160 : 1000}
            />
            {data?.events?.photographer &&
              data?.events?.photographer?.virtual_phone &&
              ability.can("send", "SMS") && (
                <div className="mt-1 mb-1">
                  <Checkbox
                    checked={sendTextMsg}
                    onChange={(e) => setSendTextMsg(e.target.checked)}
                  >
                    Send to {data?.events?.photographer?.name} as a text message{" "}
                  </Checkbox>
                  {smsText !== "" && sendTextMsg && (
                    <Popover content={smsText} title="Preview Text Message">
                      <Button type="link" size="small">
                        <EyeOutlined />
                        Preview
                      </Button>
                    </Popover>
                  )}
                </div>
              )}
            {ability.can("send", "SMS") && (
              <div className="mt-1 mb-1">
                <Checkbox
                  checked={sendToSlack}
                  onChange={(e) => setSendToSlack(e.target.checked)}
                >
                  Post to #important on Slack
                </Checkbox>
                {slackText !== "" && sendToSlack && (
                  <Popover content={slackText} title="Preview Slack Message">
                    <Button type="link" size="small">
                      <EyeOutlined />
                      Preview
                    </Button>
                  </Popover>
                )}
              </div>
            )}

            {/* <Card> */}
            <div className="contentBody">
              <Image.PreviewGroup>
                <Row gutter={[8, 8]} className="p-2">
                  {state.newPhotos.map((image) => (
                    <Col xs={24} sm={24} md={6} lg={6} xl={6} key={image.url}>
                      <Image src={image.thumbUrl} width="100%" alt="" />
                      <div>
                        <span>{image.filename}</span>
                        <span className="close-btn">
                          <Button
                            size="small"
                            danger
                            icon={<CloseOutlined />}
                            onClick={() => handleChange(image.url)}
                          />
                        </span>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Image.PreviewGroup>
            </div>
            <Row className="pt-2" justify="space-between">
              <Col span={12}>
                {!sendTextMsg && (
                  <Tooltip title="Upload one or more images" placement="bottom">
                    <label className="ant-upload">
                      <UploadOutlined />
                      <Input
                        key={randomKey || ""}
                        multiple
                        type="file"
                        onChange={handlePhotoChange}
                        hidden
                      />
                    </label>
                  </Tooltip>
                )}
              </Col>

              <Col span={12}>
                <Button
                  onClick={handleNoteCreate}
                  disabled={!touched}
                  type="primary"
                  className="float-right"
                >
                  Add Note
                </Button>
              </Col>
            </Row>
            {/* </Card> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.note,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  createNote,
  updateNote,
  removeNote,
  fetchEntityNotes,
  fetchClientNotes,
})(Notes);
